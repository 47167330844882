export const CATEGORY_MAPPING = {
	ORTHOGRAPHE: { label: 'Orthographe' },
	CONJUGATION: { label: 'Conjugaison' },
	AGREEMENT: { label: 'Grammaire' },
	PREPOSITION: { label: 'Préposition' },
	PUNCTUATION: { label: 'Ponctuation' },
	PONCTUATION_STYLE_OS: { label: 'Ponctuation' },
	PONCTUATION_POINT: { label: 'Ponctuation' },
	PONCTUATION_VIRGULE: { label: 'Ponctuation' },
	TYPOS: { label: 'Faute de frappe' },
	TYPOGRAPHY: { label: 'Typographie' },
	TYPOGRAPHIE: { label: 'Ponctuation' },
	GRAMMAR: { label: 'Grammaire' },
	GRAMMAIRE: { label: 'Grammaire' },
	CAT_GRAMMAIRE: { label: 'Grammaire' },
	STYLE: { label: 'Style' },
	CAT_HOMONYMES_PARONYMES: { label: 'Grammaire' },
	CAT_REGLES_DE_BASE: { label: 'Style' },
	REPETITIONS_STYLE: { label: 'Style' },
	MISC: { label: 'Grammaire' },
	MULTITOKEN_SPELLING: { label: 'Faute de frappe' },
	CAT_MAJUSCULES: { label: 'Majuscule' },
	CAT_MARQUES_DE_COMMERCE: { label: 'Suggestion' },
	CAT_TOURS_CRITIQUES: { label: 'Grammaire' },
	CAT_TYPOGRAPHIE: { label: 'Ponctuation' },
	SEMANTICS: { label: 'Suggestion' },
	CAT_ELISION: { label: 'Grammaire' },
	CASING: { label: 'Majuscule' },
	FAMILIARITES: { label: 'Synonyme' },
};

export const CATEGORIES_DONT_WANT_TO_SHOW = [
	'CAT_MARQUES_DE_COMMERCE',
	'SEMANTICS',
	'FAMILIARITES',
	'REPETITIONS_STYLE',
];
